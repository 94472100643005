<template>
  <v-row v-if="item && item.id" class="justify-center">
    <v-col cols="12" md="7" class="mx-auto">
      <v-card>
        <v-card-title class="headline">
          {{
            isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="caption">
          <!-- {{ item.updated_at | formatDate }} -->
          <v-spacer></v-spacer>
          {{ item.view_count }} {{ $lang("Views") }}
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text>
          <v-img class="fit-image mb-5" :src="item.banner_square"></v-img>
          <latex-html
            :html="
              $store.getters.getCurrLocale == 'hi'
                ? item.description_hi
                  ? item.description_hi
                  : item.description
                : item.description
            "
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12" class="justify-center text-center">
      {{ loading ? $lang("loading") : $lang("NOITEMS") }}
    </v-col>
  </v-row>
</template>

<script>
import { axios } from "@/plugins/axios";
import LatexHtml from "@/components/LatexHtml";

export default {
  name: "note",
  components: {
    LatexHtml,
  },
  data() {
    return {
      item: null,
      loading: false,
    };
  },
  computed: {},
  methods: {
    fetchItem() {
      this.loading = true;
      return axios
        .get("prep/notes/public/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("setAlert", {
            message: this.$lang("notfound"),
          });
          this.$router.replace({ name: "notes" });
        })
        .finally(() => {
          this.loading = true;
        });
    },
  },
  created() {
    this.fetchItem();
  },
};
</script>
<style scoped>
.fit-image {
  max-width: 85vw;
  margin: 0 auto;
}
</style>

